import { getCsrfToken, getSession, signIn } from "next-auth/react";
import { useState, useEffect, useRef } from "react";
import Head from "next/head";
import Brand from "../../components/snippets/Brand";

const loginNewUser = (email) => {
  signIn("email", { email });
};

/**
 * The login page for the application.
 * @param {string} csrfToken - The CSRF token for the application.
 * @returns The login page.
 */
const Login = ({ csrfToken }) => {
  const [authenticated, setAuthenticated] = useState(false);
  const email = useRef("");
  const [formError, setFormError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showCover, setShowCover] = useState(true);

  useEffect(() => {
    console.log(email.current);
    email.current !== "" ? loginNewUser(email.current) : console.log();
  }, [authenticated]);

  const handleChange = async (event) => {
    const user = event.target.value;
    setFormError(null);
    email.current = user;
    console.log(email);
  };

  const handleClick = (event) => {
    event.preventDefault();
    setShowCover(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    email.current = e.target.email.value;
    setLoading(true);
    console.log("Email: ", email);
    setAuthenticated(true);
    setLoading(false);
  };

  return (
    <main className='Login--Page'>
      <Head>
        <title>Get Access | Leading Ladies Toolbox</title>
        <meta
          name='description'
          content='Get access to the Leading Ladies toolbox & resources.'
        />
        <meta
          name='description'
          content='Whether you are just starting out in journey of being a pastor’s wives or a seasoned pastor’s wife. This platform is full of divinely inspired courses to empower you in navigating your role and God’s expectation of you.'
        ></meta>
        <style></style>
      </Head>
      <div className='container h-[100vh] flex flex-col justify-center items-center font-lato'>
        <div className='my-4 w-72 h-72 lg:w-96 lg:h-96'>
          <Brand color={"pink"} />
        </div>

        <div className='flex flex-col justify-center'>
          <div
            id='login-form'
            className='duration-500   mx-auto flex flex-col justify-center overflow-hidden  rounded-xl'
          >
            <div className='form-body w-96 max-w-xs py-12 px-8'>
              <form onSubmit={handleSubmit}>
                {formError ? (
                  <div className='form-messages duration-200 mb-2 -translate-y-4 flex justify-center items-center'>
                    <p className='text-sm text-red-700'>{formError}</p>
                  </div>
                ) : (
                  <></>
                )}
                <input
                  name='csrfToken'
                  type='hidden'
                  defaultValue={csrfToken}
                />
                <label htmlFor='email' className='form-label text-white'>
                  Email
                </label>
                <input
                  name='email'
                  type='email'
                  id='email'
                  onFocus={handleClick}
                  onChange={handleChange}
                  required
                  className='mt-1 block w-full px-3 py-2 bg-white border border-sky-300 outline-0 rounded-md text-sm shadow-sm placeholder-slate-400 focus:ring-0 focus:shadow-outline focus:border-sky-500 focus:placeholder-sky-300 transition duration-150 ease-in-out'
                />

                <button
                  aria-label='Login with email'
                  className={`${
                    loading ? "opacity-20" : "opacity-100"
                  } duration-500 mt-6 bg-brand-pink-dark w-full py-2 border-pink-300 shadow-sm hover:shadow-md active:shadow-sm focus:shadow-sm rounded-md`}
                  type='submit'
                >
                  Get Access with Email
                </button>
              </form>
            </div>
          </div>
          <div className='sign-up mb-4 text-white'></div>
        </div>
      </div>
    </main>
  );
};

export default Login;

export async function getServerSideProps(context) {
  const { req } = context;
  const session = await getSession({ req });
  if (session) {
    //Signed In
    return {
      redirect: { destination: "/toolbox" },
    };
  }

  const csrfToken = await getCsrfToken(context);
  return {
    props: { csrfToken },
  };
}
